<template>
  <div class="herder_box" ref="herder_box">
    <h1>{{h1}}</h1>
    <div class="nav_box">
      <div class="navTop">
        <nav v-for="item in yjzxList.list" :key="item.id" @click="yjzxclick(item)">
          <div class="img_box"><img :src="baseUrl+item.thumb" alt="" srcset=""></div>
          <div class="text">
            <p><i></i>{{item.title.indexOf(' ')==-1?item.title:item.title.slice(0,item.title.indexOf(' '))}}</p>
            <p v-if="item.title.indexOf(' ') != -1">{{item.title.slice(item.title.indexOf(' '))}}</p>
          </div>
        </nav>
      </div>
      <div class="centen_border"></div>
      <div class="navBottom">
        <p  v-for="item1 in yjzxList.list" :key="item1.id" @click="yjzxclick(item1)">{{item1.title}}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as http from '@/api/API.js'
import { ref,onMounted,watch, nextTick, reactive } from 'vue'
import { useRouter,useRoute, } from 'vue-router'
import base64_ from '@/hooks/useBase64text.js'
const route = useRoute()
const router = useRouter()

let routeData = ref(JSON.parse(base64_.decode(route.params.obj)))
let h1 = ref(routeData.value.title3?routeData.value.title3:routeData.value.title2?routeData.value.title1:routeData.value.title1)
const baseUrl = process.env.VUE_APP_BASE_URL + '/'
let yjzxList = reactive({
  list:[]
})
const herder_box = ref(null)
const getarticelList = async (arid) => {
  const {data:res} = await http.get_article_list(arid)
  if(res.code == 200){
    yjzxList.list = res.data.data
    h1.value = routeData.value.title3?routeData.value.title3:routeData.value.title2?routeData.value.title2:routeData.value.title1
    if(herder_box.value){
    herder_box.value.style.opacity = 1
    herder_box.value.style.transform = 'translateY(0)'
    }
  }
}
getarticelList({type_id:routeData.value.article_id})
onMounted(() => {
  watch(() => route.params,() => {
     route.params.obj?routeData.value = JSON.parse(base64_.decode(route.params.obj)):''
     // 判断如果路由没有参数就不请求数据，（优化减少请求）
     if(route.params.obj){
      getarticelList({type_id:routeData.value.article_id})
     }
  })
  herder_box.value.style.opacity = 0
  herder_box.value.style.transform = 'translateY(800px)'
})
// 点击列表，跳转到对应详情数据
let yjzxclick = (item) => {
  if(item.jump_url){
    window.open(item.jump_url)
  }else{
    router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
      active:routeData.value.active,
      article_wz_id:item.id,
      id:routeData.value.id,
      index:routeData.value.index,
      level:routeData.value.level,
      title1:routeData.value.title1,
      title2:routeData.value.title2,
      title3:routeData.value.title3,
      title4:item.type_name
    }))}
  })
  }
}
</script>

<style lang="less" scoped>
@maxw:128rem;
.herder_box{
  width: 100%;
  padding: 0 !important;
  margin-left: 30/@maxw;
  transition: ease .8s;
>h1{
  width: 100%;
  margin: 0;
  font-family: fantBold;
  font-weight: 300;
  color: #174994;
  border-bottom:2/@maxw solid #174994;  
  padding: 0 0 20/@maxw 0;
  font-size: 39/@maxw;
}
.navTop{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 60/@maxw;
  width: 100%;
  cursor: pointer;
  >nav{
    position: relative;
    margin-right: 2/@maxw;
    width: 345/@maxw;
    height: 217/@maxw;
    margin-bottom: 160/@maxw;
    .img_box{
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
      img{
      width: 100%;
      height: 100%;
      vertical-align: top;
      transition: ease .3s;
    }
    }
    
    .text{
      margin: 33/@maxw 0 84/@maxw 0;
      // width: 75%;
      width: 100%;
      height: 60/@maxw;
      p{
        display: flex;
        align-items: center;
        margin: 0;
        font-size: 22/@maxw;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #174994;
        >i{
          display: inline-block;
          min-width: 6/@maxw;
          width: 6/@maxw;
          height: 28/@maxw;
          background: linear-gradient(118deg, #174994 0%, #387DE2 100%);
          margin-right: 10/@maxw;
          }
      }
      >p:nth-child(2){
        padding-left: 16/@maxw;
      }
    }
  }
  >nav::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 10/@maxw;
    left: -10/@maxw;
    background: linear-gradient(58deg, #CBE0FF 0%, #B2D1FF 100%);
    z-index: -1;
  }
  >nav:nth-child(4){
    width: 725/@maxw;
    height: 217/@maxw;
  }
  >nav:hover{
    .img_box{
      img{
        transform: scale(1.03);
      }
    }
  }
}
.centen_border{
  margin-top: 0;
  width: 100%;
  height: 2/@maxw;
  background-color: #517DBE;
}
.navBottom{
  margin: 50/@maxw 0 0 0;
  p{
    margin-bottom: 10/@maxw;
    font-size: 25/@maxw;
    width: 80%;
    color: rgb(188,169,151);
    font-weight: bold;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    transition: ease .3s;
  }
  >p:hover{
    box-shadow: 0 0 5/@maxw rgb(188,169,151);
  }
}
}
.centen_border,.navBottom{
  display: none;
}
@media screen and (max-width:750px) {
  @maxw_:750/100vw;
  .herder_box{
    >h1{
      font-size: 39/@maxw_ !important;
    }
  }
  .navTop{
    display: none !important;
  }
  .navBottom{
    display: block !important;
    >p {
      font-size: 30/@maxw_ !important;
    }
  }
}
</style>